/* FixedColumnsTable.css */
table {
    border-spacing: 0;
  }
  
  th,
  td {
    padding: 8px 16px;
    white-space: nowrap;
  }
  
  th.sticky,
  td.sticky {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
  }
  
  th.sticky.left-0,
  td.sticky.left-0 {
    left: 0;
  }
  
  th.sticky.right-0,
  td.sticky.right-0 {
    right: 0;
  }