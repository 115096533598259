/* DateTimePicker.css */
  
.react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
  }
  
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #3182ce;
    color: white;
  }
  
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #3182ce;
    color: white;
  }
  